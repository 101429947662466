<template>
  <b-card :title="$t('parties')">
    <b-row>
      <b-col md="7" class="mb-2">
        <b-input-group class="mt-3">
          <b-button size="sm" variant="outline-secondary" title="Скинути фільтр">
            <fa icon="filter" />
          </b-button>
          <select class="form-control form-control-sm" @change="selectWorker">
              <option value="">-- {{ $t('select_worker') }} --</option>
              <option v-for="item in workers" :value="item.id" :key="item.id">{{ item.text }}</option>
          </select>
          <!--<b-form-datepicker
            id="shift-datepicker"
            v-model="partyData"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="ru"
            @input="changeDate"
            size="sm"
          ></b-form-datepicker>-->
          <select class="form-control form-control-sm" @change="changeDate">
            <option v-for="(item, index) in shiftDates" :key="index" :value="item.id">{{ item.created_at }}</option>
          </select>
          <select class="form-control form-control-sm" @change="changePartiesShift">
            <option value="">-- {{ $t('select_process') }} --</option>
            <option v-for="item in shifts" :value="item.id" :key="item.id">{{ item.text }}</option>
          </select>
        </b-input-group>
      </b-col>
      <b-col md="5" class="mb-2 text-right">
        <!--<b-button-group>
            <b-button size="sm" variant="outline-success" @click="setDoneParty">{{ $t('close_parties') }}</b-button>
          </b-button-group>-->
      </b-col>
    </b-row>
    <b-table responsive
      show-empty
      sort-icon-left
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="0"
      v-model="currentItems">
        <template v-slot:head(selected)>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="check-tasks" @click="selectAll" />
            <label class="custom-control-label" for="check-tasks"></label>
          </div>
        </template>
        <template v-slot:cell(selected)="row">
          <b-button-group>
          <div class="custom-control custom-checkbox">
              <input
              type="checkbox"
              class="custom-control-input"
              :id="`checkbox-${row.item.id}`"
              :name="`checkbox-${row.item.id}`"
              :value="row.item.id"
              v-model="selectIds">
              <label class="custom-control-label" :for="`checkbox-${row.item.id}`"></label>
          </div>
          <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info" pill>
            <fa :icon="row.detailsShowing ? 'minus' : 'plus'" />
          </b-button>
        </b-button-group>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge v-if="row.item.status === 1" variant="warning">Закрита</b-badge>
          <b-badge v-else-if="row.item.status === 2" variant="success">Укомплектовано</b-badge>
          <b-badge v-else-if="row.item.status === 3" variant="danger">Відміна</b-badge>
          <b-badge v-else variant="secondary">Не закрита</b-badge>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button v-if="row.item.status !== 2" size="sm" variant="outline-success" @click="setSignleDone(row.item.id)">{{ $t('set_ready') }}</b-button>
        </template>
        <template v-slot:row-details="row">
          <table class="table table-sm table-light">
            <thead>
              <th>{{ $t('order_num') }}</th>
              <th>{{ $t('product') }}</th>
              <th>{{ $t('stage') }}</th>
              <th class="text-center">{{ $t('pos_in_party') }}</th>
              <th class="text-center">{{ $t('delivery') }}</th>
              <th>{{ $t('status') }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(item, idxs) in row.item.parties" :key="idxs">
                <td>{{ item.order_num }}</td>
                <td>
                  {{ item.product_name }}
                </td>
                <td>{{ item.stage }}</td>
                <td class="text-center">{{ item.pos_in_party }}</td>
                <td class="text-center">{{ item.delivery }}</td>
                <td>
                  <b-badge v-if="item.status === 0" variant="primary">{{ $t('new') }}</b-badge>
                  <b-badge v-else-if="item.status === 1" variant="success">{{ $t('done') }}</b-badge>
                  <b-badge v-else variant="danger">{{ $t('cancel') }}</b-badge><!-- status == 2 -->
                </td>
                <td >
                  <b-button-group>
                    <b-button v-if="item.status === 0 || item.status === 2" size="sm" variant="primary" @click="setStatus(item.id, 'pack')" title="В упаковку">
                      <fa icon="box-open" />
                    </b-button>
                    <b-button v-if="item.status === 0 || item.status === 1" size="sm" variant="danger" @click="setStatus(item.id, 'cancel')" title="Відміна">
                      <fa icon="ban" />
                    </b-button>
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </b-table>
       <!-- Pagination -->
  <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
  </b-card>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
export default {
  middleware: 'auth',
  layout: 'default',
  metaInfo () {
    return { title: this.$t('parties') }
  },
  data () {
    return {
      items: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false
        },
        {
          key: 'party_num',
          label: '№ партії',
          typesort: 'text',
          class: 'text-center'
        },
        {
          key: 'shift',
          label: 'Зміна',
          class: 'text-center'
        },
        {
          key: 'process',
          label: 'Процес',
          class: 'text-center'
        },
        {
          key: 'created_at',
          label: 'Дата'
        },
        {
          key: 'count',
          label: 'К-сть в партії',
          class: 'text-center'
        },
        {
          key: 'status',
          label: 'Статус',
          class: 'text-center'
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right'
        }
      ],
      currentItems: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      urlParams: {},
      selected: [],
      selectIds: [],
      selectIdStages: [],
      selectProductStages: [],
      partiesData: [],
      workers: [],
      shifts: [],
      shiftDates: [],
    }
  },
  mounted () {
    this.fetchWorkers()
  },
  computed: {
    ...mapGetters({
      partyValue: 'shift/partiesValue',
      currentShift: 'shift/shift'
    })
  },
  methods: {
    async getShiftDates (id) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/dates', { user: id }).then(resp => {
        this.shiftDates = resp.data
      })
    },
    async setStatus (id, status) {
      if (this.currentShift) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'parties/party/status', { pid: id, status: status })
        this.$root.$refs.loading.finish()
        this.fetchData()
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Відкрийте або поновіть зміну',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    setDoneParty () {
      if (this.currentShift) {
        for (const item in this.items) {
          const party = this.items.find(p => p.id === this.items[item].id)
          if (party) {
            const f = this.selectIds.find(i => i === party.id)
            if (typeof f !== 'undefined') {
              if (party.count > this.partyValue) {
                Swal.fire({
                  title: 'Помилка!',
                  text: 'Партія №' + party.party_num + ' не заповнена до кінця',
                  confirmButtonText: 'Oк'
                })
              } else {
                // save status
                console.log('Save..')
              }
            }
          }
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Відкрийте або поновіть зміну',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    async setSignleDone (id) {
      if (this.currentShift) {
        if (id) {
          this.$root.$refs.loading.start()
          const apiUrl = process.env.VUE_APP_ENDPOINT_V1
          await axios.post(apiUrl + 'parties/done/' + id)
          this.$root.$refs.loading.finish()
          this.fetchData()
        }
      } else {
        Swal.fire({
          title: 'Помилка!',
          text: 'Відкрийте або поновіть зміну',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    selectAll (e) {
      this.selectIds = []
        if (e.target.checked) {
          for (const item in this.items) {
            this.selectIds.push(this.items[item].id)
            const party = this.items.find(o => o.id === this.items[item].id)
            if (party.parties) {
              const parties = []
              party.parties.forEach(p => {
                const st = this.selectIdStages.find(si => si === p.id)
                if (typeof st === 'undefined') {
                  this.selectIdStages.push(p.id)
                }
                parties.push(p.id)
              })
              this.selectProductStages = parties
            }
            // set parties data
            const wd = this.partiesData.find(w => w.orderId === this.items[item].id)
            if (typeof wd === 'undefined') {
              this.partiesData.push({
                partyId: this.items[item].id,
                stages: this.selectProductStages
              })
            } else {
              this.partiesData = []
              this.partiesData.push({
                partyId: this.items[item].id,
                stages: this.selectProductStages
              })
            }
          }
        } else {
          this.selectIdStages = []
          // set parties data
          this.partiesData = []
          this.selectProductStages = []
        }
    },
    showAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', true)
      })
    },
    hideAllDetails () {
      this.currentItems.forEach((item) => {
        this.$set(item, '_showDetails', false)
      })
    },
    changeDate (e) {
      const val = e.target.value
      this.urlParams.date = val
      this.fetchData()
    },
    selectWorker (e) {
      const id = e.target.value
      if (id) {
        this.getShiftDates(id)
        // working process
        this.fetchShifts(id)
        this.getShiftDates(id)
        this.urlParams.worker = id
        this.fetchData()
      } else {
        // clear shifts array
        this.shifts = []
      }
    },
    changePartiesShift (e) {
      const val = e.target.value
      this.urlParams.shift = val
      this.fetchData()
    },
    async fetchShifts (workerId) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.post(apiUrl + 'shift/shifts', { w: workerId })
      this.shifts = data
    },
    async fetchData () {
      const params = this.buildQuery() !== '' ? '&' + this.buildQuery() : ''
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(
        apiUrl + 'parties?page=' + this.currentPage + params
      )
      this.items = data.data
      this.currentPage = data.meta.pagination.current_page
      this.perPage = data.meta.pagination.per_page
      this.totalItems = data.meta.pagination.total
    },
    async fetchWorkers () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await axios.get(apiUrl + 'worker/workers')
      this.workers = data
    },
    buildQuery () {
      return Object.entries(this.urlParams)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&')
    },
    clearFilter () {
      this.urlParams = {}
      this.fetchData()
    }
  },
  watch: {
    currentPage: function (value) {
      this.fetchData().catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>
<style>
.table {
  font-size: 0.9rem !important;
}
.table th,
.table td {
  padding: 0.3rem !important;
}
</style>
